@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px;
  background-color: theme("colors.slate.50");
}

* {
  font-size: 1em;
}

/*  ------------  */
/*   RESET CSS    */
/*  ------------  */

input,
textarea,
button {
  outline: none;
  border: none;
  all: unset;
}
input[type="color"] {
  width: 0;
  height: 0;
  visibility: hidden;
  position: absolute;
  top: 30px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.invisiblescroll {
  scrollbar-width: thin;
  -ms-overflow-style: none;
  overflow: scroll;
}
.invisiblescroll::-webkit-scrollbar {
  display: none;
}

/*  ------------  */
/*   ANIMATIONS   */
/*  ------------  */

[data-animation="entrance-fade"] {
  animation: entrance-fade 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

[data-animation="exit-fade"] {
  animation: exit-fade 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

[data-animation="entrance-top"] {
  animation: entrance-top 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

[data-animation="entrance-bottom"] {
  animation: entrance-bottom 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

[data-animation="exit-bottom"] {
  animation: exit-bottom 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

[data-animation="entrance-left"] {
  animation: entrance-left 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes entrance-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes exit-fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes entrance-top {
  0% {
    opacity: 0;
    transform: scale(0.98) translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

@keyframes entrance-bottom {
  0% {
    opacity: 0;
    transform: scale(0.98) translateY(30px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

@keyframes exit-bottom {
  0% {
    opacity: 0;
    transform: scale(0.98) translateY(30px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

@keyframes entrance-left {
  0% {
    opacity: 0;
    transform: scale(0.98) translateX(-40px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateX(0);
  }
}
