#page-editor [data-element="element"],
#page-editor [data-element="box"],
#page-editor [data-element="grid"],
#page-editor .element-focused {
  outline-offset: 0px;
  outline-width: 1px;
  outline-style: dashed;
  outline-color: transparent;
  transition: 200ms all ease;
}

#page-editor .element-focused[data-element="element"],
#page-editor [data-element="element"]:focus-within {
  outline-color: theme("colors.teal.500");
  outline-offset: 0px;
}

#page-editor .element-focused[data-element="box"],
#page-editor [data-element="box"]:focus-within {
  outline-color: theme("colors.sky.400");
  outline-offset: 0px;
}

#page-editor .element-focused[data-element="grid"],
#page-editor [data-element="grid"]:focus-within {
  outline-color: theme("colors.violet.400");
  outline-offset: 0px;
}

#page-editor .padding-view {
  outline-width: 1px;
  outline-style: dashed;
  outline-offset: 0px;
}

#page-editor .padding-view.element {
  outline-color: theme("colors.teal.500");
  background-color: theme("colors.teal.100");
}

#page-editor .padding-view.box {
  outline-color: theme("colors.sky.400");
  background-color: theme("colors.sky.100");
}

#page-editor .padding-view.grid {
  outline-color: theme("colors.violet.400");
  background-color: theme("colors.violet.100");
}

#page-editor [data-type="line"] {
  width: 100%;
  padding-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid theme("colors.slate.600");
}

#page-editor [data-type="button"] {
  display: inline-grid;
  vertical-align: top;
  align-items: center;
}

#page-editor [data-type="button"] input {
  outline: none;
  text-align: center;
  resize: none;
  padding: 0;
  transition: all 0.2s ease-in-out;
}

#page-editor [data-type="button"]::after,
#page-editor [data-type="button"] input {
  width: auto;
  min-width: 1em;
  grid-area: 1 / 2;
  font: inherit;
  margin: 0;
  background: none;
  appearance: none;
  border: none;
}

#page-editor [data-type="button"]::after {
  content: attr(data-value) " ";
  visibility: hidden;
  white-space: pre-wrap;
}

#page-editor textarea {
  outline: none;
  all: unset;
  width: 100%;
  overflow: hidden;
  resize: none;
  border-radius: 0.09em;
  transition: all 0.2s ease-in-out;
  line-height: normal;
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: unset;
  outline-offset: 4px;
  outline: 2px solid transparent !important;
}

#page-editor textarea[data-type="heading"] {
  position: relative;
  font-weight: 600;
}

#page-editor [data-element="element"] {
  transition: 300ms transform ease;
}

#page-editor [data-element="element"][data-form-italic="true"] {
  font-style: italic;
}

#page-editor [data-element="element"][data-form-bold="true"] * {
  font-weight: 600;
}

#page-editor [data-element="element"][data-form-underline="true"] {
  text-decoration: underline;
}

#page-editor [data-element="element"][data-form-textalign="left"] {
  text-align: left;
}

#page-editor [data-element="element"][data-form-textalign="center"] {
  text-align: center;
}

#page-editor [data-element="element"][data-form-textalign="right"] {
  text-align: right;
}

#page-editor [data-element="element"][data-form-textalign="justify"] {
  text-align: justify;
}

#page-editor [data-element="element"][data-form-hover="scale"]:hover {
  transform: scale(1.02);
}

#page-editor [data-element="element"][data-form-hover="fade"]:hover {
  opacity: 0.8;
}

#page-editor [data-element="grid"].drag-enter::after {
  display: flex;
  align-items: center;
  justify-content: center;
  content: "Insert here";
  width: 100%;

  padding: 20px;
  background-color: theme("colors.violet.100");
  border-radius: 10px;
  border: 1px dashed theme("colors.violet.300");
  color: theme("colors.violet.400");
}
