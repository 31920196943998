input[data-component="input"] {
  width: auto;
  border-radius: 0.6em;
  background-color: WHITE;
  color: theme("colors.slate.500");
  border: 1px solid theme("colors.slate.200");
  padding: 0.5rem 0.6rem;
  transition: all 0.4s ease;
  outline: 2px solid transparent;
  outline-offset: 1px;
}

input[data-component="input"]:focus {
  outline-offset: 1px;
  outline: 2px solid theme("colors.teal.600");
}
