button[data-component="button"] {
  border-radius: 0.5em;
  padding: 0.7rem 0.7rem;
  transition: all 0.4s ease;
  cursor: pointer;
  user-select: none;
  display: flex;
  gap: 0.3rem;
  line-height: 1;
  white-space: pre;
  width: inherit;
}

button[data-type="primary"] {
  background-color: theme("colors.teal.500");
  color: WHITE;
  box-shadow: 0 0 0 0px theme("colors.teal.100");
}

button[data-type="primary"] > span::before {
  color: WHITE;
}

button[data-type="primary"]:hover {
  background-color: theme("colors.teal.600");
  box-shadow: 0 0 0 2px theme("colors.teal.100");
}
button[data-type="primary"][aria-disabled="true"] {
  opacity: 0.8;
  cursor: not-allowed;
  pointer-events: none;
}

button[data-type="ghost"] {
  background-color: theme("colors.slate.200");
  color: theme("colors.slate.600");
}

button[data-type="ghost"]:hover {
  background-color: theme("colors.slate.300");
}

button[data-type="ghost"][aria-disabled="true"] {
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none;
}

button[data-type="bubble"] {
  border-radius: 100%;
  background-color: theme("colors.transparent");
  color: theme("colors.slate.600");
}

button[data-type="bubble"]:hover {
  background-color: theme("colors.slate.200");
}

button[data-type="bubble"][data-active="true"] {
  background-color: theme("colors.slate.300");
}

button[data-type="bubble"][aria-disabled="true"] {
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none;
}

button[data-type="dropdown"] {
  padding: 0.7rem;
  background-color: theme("colors.white");
  color: theme("colors.slate.600");
}

button[data-type="dropdown"]:hover {
  background-color: theme("colors.slate.100");
}
button[data-type="dropdown"][data-active="true"] {
  background-color: theme("colors.slate.100");
}
button[data-type="dropdown"][aria-disabled="true"] {
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none;
}

button[data-type="dropdown"][data-color="danger"]:hover,
button[data-type="dropdown"][data-color="danger"]:hover > span::before {
  color: theme("colors.rose.600");
  background-color: theme("colors.rose.50");
}

button[data-type="flat"] {
  background-color: transparent;
  color: theme("colors.slate.600");
}

button[data-type="flat"]:hover {
  background-color: theme("colors.slate.100");
}
button[data-type="flat"][data-active="true"] {
  color: theme("colors.teal.500");
  background-color: theme("colors.teal.50");
}
button[data-type="flat"][data-active="true"] > span::before {
  color: theme("colors.teal.500");
}

button[data-type="flat"][aria-disabled="true"] {
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none;
}

button[data-type="link"] {
  padding: 0;
  color: theme("colors.slate.600");
}

button[data-type="link"]:hover {
  color: theme("colors.teal.600");
}

button[data-type="link"][data-active="true"] {
  background-color: theme("colors.teal.50");
}
button[data-type="link"][data-active="true"] > span::before {
  color: theme("colors.teal.500");
}

button[data-type="link"][aria-disabled="true"] {
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none;
}
button[data-type="link"][data-color="danger"]:hover,
button[data-type="link"][data-color="danger"]:hover > span::before {
  color: theme("colors.rose.600");
}

button[data-size="small"] {
  padding: 0.575rem;
  font-size: 1em;
  border-radius: 0.5em;
}

button[data-size="small"] > span[class^="mgc_"] {
  font-size: 0.85rem;
}
button[data-size="xsmall"] {
  padding: 0.35rem;
  font-size: 0.8rem;
  border-radius: 0.5em;
}

button[data-size="xsmall"] > span[class^="mgc_"] {
  font-size: 0.9rem;
}
